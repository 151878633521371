import React, { Component } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Slider from "react-slick"
import Fade from "react-reveal/Fade"
import { isAndroid } from "react-device-detect"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import SEO from "../../components/core/seo"

import linkBackgroundImage from "../../images/graphic-link-bg-jugl.png"

import desktopA from "../../images/work/jugl/work-jugl-0-desktop.jpg"
import desktopB from "../../images/work/jugl/work-jugl-1-desktop.jpg"

import mobileA from "../../images/work/jugl/work-jugl-0-mobile.jpg"
import mobileB from "../../images/work/jugl/work-jugl-1-mobile.jpg"
import mobileC from "../../images/work/jugl/work-jugl-2-mobile.jpg"
import mobileD from "../../images/work/jugl/work-jugl-3-mobile.jpg"

import socialOG from "../../images/social-opengraph-work-jugl.png"

import FixedBackground from "../../components/elements/image-background-fixed"
import Layout from "../../components/core/layout"
import PrimaryImageWE from "../../components/work/image-work-jugl"
import Contact from "../../components/contact"
import ShowcaseX from "../../components/elements/image-work-X-jugl"
import PrimaryButton from "../../components/button/primary-button"
import NextPrev from "../../components/button/next-prev-buttons"

// Components

const PrimarySpacer = styled.div`
  margin-top: 200px;
`

const HeaderSpacer = styled.div`
  height: 120px;
  ${media.lessThan("medium")`
        display: none;
    `}
`

const FlexSpacer = styled.div`
  flex-grow: 1;
  ${media.lessThan("medium")`
        display: none;
    `}
`

const SectionHeader = styled.div`
  font-family: "Nitti Bold";
  margin-top: 65px;
  margin-bottom: 35px;
  pointer-events: none;

  ${media.lessThan("medium")`
    font-size: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
  `}
`

const SectionContent = styled.div`
  line-height: 1rem;

  ${media.lessThan("medium")`
      width: 300px;
      font-size: 13px;
      line-height:1.1rem;
  `}
`

const WorkClient = styled.div`
  font-family: "Roobert Bold";
  padding-top: 140px;
  font-size: 3.375rem;
  line-height: 2.9rem;
  white-space: nowrap;
  pointer-events: none;

  ${media.lessThan("medium")`
    font-size: 2.1rem;
    line-height: 2rem;
    margin-top: 90px;
    padding-top: 20px;
    width: 200px;
  `}
`

const WorkSubhead = styled.div`
  font-family: "Roobert";
  font-size: 20px;
  margin-top: 20px;
  line-height: 1.1;

  ${media.lessThan("medium")`
        width: 250px;
        margin-top: 10px;
        margin-bottom: 30px;
        font-size: 14px;
    `}
`

const SectionHorizontalRule = styled.div`
  height: 1px;
  width: 80px;
  margin-top: 80px;
  margin-bottom: 80px;
  background-color: #535663;
  margin-left: auto;
  margin-right: auto;

  ${media.lessThan("medium")`
        margin-top: 0px;
    `}
`

// Main Section

const IndexContainer = styled.div`
  overflow-x: hidden;
  width: 100vw;
  height: 100%;
`

const MainContainer = styled.div`
  display: flex;
  align-items: flex-end;

  ${media.lessThan("medium")`
        flex-direction: column;
        align-items: flex-start;
    `}
`

const MainLeft = styled.div`
  flex-grow: 1;
  max-width: 50%;
  padding-right: 80px;

  ${media.lessThan("medium")`
        padding-right: 0px;
    `}
`

const VerticalRule = styled.div`
  height: 400px;
  width: 1px;
  bottom: 0px;
  background-color: #535663;

  ${media.lessThan("medium")`
    width: 4px;
    display: none;
  `}
`

const HorizontalRule = styled.div`
  display: none;

  ${media.lessThan("medium")`
    display: block;
    width: 4px;
    height: 1px;
    width: 100%;
    margin-top: 80px;
    background-color: #535663;
  `}
`

const MainRight = styled.div`
  display: flex;
  padding-left: 80px;
  padding-top: 20px;
  flex-grow: 1;
  max-width: 50%;
  flex-direction: column;
  align-items: baseline;

  ${media.lessThan("medium")`
        padding-left: 0px;
        padding-top: 0px;
        flex-grow: 0;
        max-width: 90%;
        margin-bottom: 80px;
    `}
`

const SectionImpact = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`

const ImpactContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: stretch;
`

const ImpactTotal = styled.div`
  font-family: "Roobert Bold";
  font-size: 40px;

  ${media.lessThan("medium")`
        font-size: 26px;
    `}
`

const ImpactDescription = styled.div`
  font-family: "Nitti Bold";
  font-size: 13px;
  line-height: 0.8rem;
  margin-top: 12px;
  max-width: 120px;

  ${media.lessThan("medium")`
        font-family: "Nitti Normal";
        font-size: 11px;
        max-width: 80px;
    `}
`

// Middle Section

const MiddleContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  ${media.lessThan("medium")`
        flex-direction: column;
        justify-content: stretch;
    `}
`

const ClientQuote = styled.div`
  font-family: "Roobert SemiBold";
  line-height: 1.1;
  font-size: 40px;
  text-align: center;
  margin-bottom: 45px;
  pointer-events: none;

  ${media.lessThan("medium")`
        font-size: 20px;
        width: 80vw;
        margin-bottom: 25px;
    `}
`

const ClientName = styled.div`
  text-align: center;
  line-height: 1.1;
  pointer-events: none;

  ${media.lessThan("medium")`
        margin-bottom: 80px;
    `}
`

const PhaseDisciplineContainer = styled.ul`
  justify-self: baseline;
`

const PhaseDisciplines = styled.li`
  line-height: 0.5;
  white-space: nowrap;

  ${media.lessThan("1220px")`
    font-size: 12px;
  `}

  ${media.lessThan("medium")`
    font-size: 11px;
  `}
`

// Showcase

const ShowcaseContainer = styled.div`
  display: flex;
  font-family: "Roobert Bold";
  text-align: center;
  font-size: 40px;
  height: 80vh;

  align-items: center;
  justify-content: center;
  margin-top: 80px;

  /* Firefox */
  @-moz-document url-prefix() {
    & {
      margin-top: 200px;
      margin-bottom: 100px;
    }
  }

  /* Microsoft IE10 and above */
  @media all and (-ms-high-contrast: none) {
    & {
      margin-top: 200px;
      margin-bottom: 100px;
    }
  }

  /* Microsoft Edge */
  @supports (-ms-ime-align: auto) {
    & {
      margin-top: 200px;
      margin-bottom: 100px;
    }
  }

  ${media.lessThan("medium")`
        margin-top: 0px;
        flex-direction: column;
        margin-bottom: 80px;
    `}
`

const ShowcaseSliderDesktop = styled(Slider)`
  width: 800px;
  height: 500px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 8px 10px rgba(0, 0, 0, 0.5);
  mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);

  ${media.lessThan("medium")`
        border-radius: 10px;
        width: 90vw;
        height: 0;
        padding-bottom: 62.5%;
        position: relative;
    `}
`

const ShowcaseSliderMobile = styled(Slider)`
  margin-top: 120px;
  margin-left: -40px;
  width: 200px;
  height: 437px;
  border-radius: 16px;
  overflow: hidden;
  mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);

  ${media.lessThan("medium")`
        border-radius: 10px;
        height: 410px;
        margin-left: -10px;
        margin-top: 40px;
    `}
`

const ShowcaseSliderShadow = styled.div`
  width: 200px;
  height: 437px;
  border-radius: 16px;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  margin-left: 380px;
  margin-top: 60px;

  /* Firefox */
  @-moz-document url-prefix() {
    & {
      display: none;
    }
  }

  /* Microsoft IE10 and above */
  @media all and (-ms-high-contrast: none) {
    & {
      display: none;
    }
  }

  /* Microsoft Edge */
  @supports (-ms-ime-align: auto) {
    & {
      display: none;
    }
  }

  ${media.lessThan("medium")`
        border-radius: 10px;
        height: 365px;
        margin-left: -5px;
        margin-top: 118px;
    `}
`

const ProjectCounter = styled.div`
  position: absolute;
  text-align: right;
  font-family: "Roobert SemiBold";
  font-size: 2rem;
  line-height: 1.8rem;
  margin-top: -325px;
  margin-bottom: 2.2rem;
  flex-grow: 1;
  right: 165px;
  pointer-events: none;

  /* Firefox */
  @-moz-document url-prefix() {
    & {
      margin-top: -410px;
    }
  }

  /* Microsoft IE10 and above */
  @media all and (-ms-high-contrast: none) {
    & {
      margin-top: -410px;
    }
  }

  /* Microsoft Edge */
  @supports (-ms-ime-align: auto) {
    & {
      margin-top: -410px;
    }
  }

  ${media.lessThan("medium")`
    margin-top: ${isAndroid ? -134 : -130}px
    margin-right: 30px;
    font-size: 18px;
    line-height: 1rem;
    margin-top: 20px;
    right: 10px;
  `}
`

// More Work

const MoreClient = styled.div`
  margin-bottom: -20px;
  margin-left: 18px;
  margin-right: 18px;

  ${media.lessThan("medium")`
        margin-bottom: -8px;
    `}
`

const MoreWork = styled.div`
  display: flex;
  justify-content: stretch;
  margin-top: 80px;
  margin-bottom: -40px;
`

const PreviousWork = styled.div`
  display: flex;
  align-items: center;
  font-family: "Roobert SemiBold";
  font-size: 32px;

  ${media.lessThan("medium")`
        font-size: 16px;
    `}
`

const WorkSpacer = styled.div`
  flex-grow: 1;
`

const NextWork = styled.div`
  display: flex;
  align-items: center;
  font-family: "Roobert SemiBold";
  font-size: 32px;

  ${media.lessThan("medium")`
        font-size: 16px;
    `}
`

const ButtonArrow = styled.div`
  font-size: 80px;
  font-family: "Roobert SemiBold";
  line-height: 1.1;
  color: #b8b8b8;

  &:hover {
    color: #b8b8b8;
  }

  ${media.lessThan("medium")`
        font-size: 40px;
    `}
`

class CaseStudy extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSlide: 1,
    }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  next() {
    this.slider.slickNext()
    this.mobileSlider.slickNext()
  }

  previous() {
    this.slider.slickPrev()
    this.mobileSlider.slickPrev()
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 6000,
    }
    return (
      <IndexContainer>
        <FixedBackground />
        <PrimaryImageWE />
        <Layout activeSection="work">
          <SEO
            title="Futurehaus: Jugl Case Study"
            description="Making it easier for working parents to coordinate events and logistics"
            url="https://futurehaus.studio/work/jugl"
            opengraph={socialOG}
          />
          <MainContainer>
            <MainLeft>
              <PrimarySpacer />
              <WorkClient>Jugl</WorkClient>
              <WorkSubhead>
                Making it easier for working parents <br />
                to coordinate events and logistics
              </WorkSubhead>
              <SectionHeader>Where it started</SectionHeader>
              <SectionContent>
                Jugl had a great idea: create an app to help working parents
                better coordinate their many events and complex logistics. The
                founders spent two years developing and refining their product;
                however, like many start-ups, they were stuck just short of the
                finish line when they decided to partner with Futurehaus.
              </SectionContent>
              <HeaderSpacer />
            </MainLeft>
            <VerticalRule />
            <HorizontalRule />
            <MainRight>
              <FlexSpacer />
              <SectionHeader>Results</SectionHeader>
              <PhaseDisciplineContainer>
                <PhaseDisciplines className="discipline-bullet juglBrand">
                  New version of mobile app, redesigned and <br />
                  <div style={{ marginTop: "10px" }}>
                    rearchitected from the ground up
                  </div>
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet juglBrand">
                  Research-based insights to understand users’ needs
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet juglBrand">
                  New, validated web app prototype
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet juglBrand">
                  Recommendations on building sustainable <br />
                  <div style={{ marginTop: "10px" }}>business model</div>
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet juglBrand">
                  Continuing collaboration to build out <br />
                  <div style={{ marginTop: "10px" }}>
                    experimental new features
                  </div>
                </PhaseDisciplines>
              </PhaseDisciplineContainer>
              <SectionHeader>Metrics at a glance</SectionHeader>
              <ImpactContainer>
                <SectionImpact>
                  <div>
                    <ImpactTotal>2</ImpactTotal>
                    <ImpactDescription>
                      Private beta <br />
                      releases
                    </ImpactDescription>
                  </div>
                  <div>
                    <ImpactTotal>400%</ImpactTotal>
                    <ImpactDescription>
                      Increase in
                      <br />
                      app performance
                    </ImpactDescription>
                  </div>
                  <div />
                  <div />
                  <div />
                </SectionImpact>
              </ImpactContainer>
            </MainRight>
          </MainContainer>
          <ShowcaseContainer>
            <ShowcaseX />
            <NextPrev
              darkMode
              color="#b5a0cc"
              previous={this.previous}
              next={this.next}
            />
            <ProjectCounter>
              <div>0{this.state.currentSlide}</div>
              <div>/04</div>
            </ProjectCounter>
            <ShowcaseSliderDesktop
              ref={c => (this.slider = c)}
              {...settings}
              afterChange={currentSlide => {
                this.setState({ currentSlide: currentSlide + 1 })
              }}
            >
              <div key={1}>
                <img
                  alt="Desktop view of Jugl web app, where user is creating a new School Assembly event."
                  src={desktopA}
                />
              </div>
              <div key={2}>
                <img
                  alt="Desktop view of Jugl web app, where Susan is responding to a family member, Mark, to confirm they can drive back from the soccer game."
                  src={desktopB}
                />
              </div>
            </ShowcaseSliderDesktop>
            <ShowcaseSliderShadow />
            <ShowcaseSliderMobile
              ref={c => (this.mobileSlider = c)}
              {...settings}
            >
              <div key={1}>
                <img
                  alt="Calendar view of the Jugl mobile app. Includes events for the next four days."
                  src={mobileA}
                />
              </div>
              <div key={2}>
                <img
                  alt="Add new event screen, where a user is creating a new event to play tennis."
                  src={mobileB}
                />
              </div>
              <div key={3}>
                <img
                  alt="Schedule view of the Jugl mobile app, with the Calendar view collapsed. Includes five events."
                  src={mobileC}
                />
              </div>
              <div key={4}>
                <img
                  alt="Schedule view of the Jugl mobile app, where the user has swiped on an event to show the Quick Actions menu."
                  src={mobileD}
                />
              </div>
            </ShowcaseSliderMobile>
          </ShowcaseContainer>
          <Fade bottom cascade duration={1250} distance="25px">
            <MiddleContainer>
              <MainLeft style={{ minHeight: 0 }}>
                <SectionHeader>What we did</SectionHeader>
                <SectionContent>
                  Bringing a fresh perspective to their existing research, we
                  helped Jugl prioritize the most impactful and unique features
                  of their product, allowing us to quickly rebuild their app.
                  During initial user interviews we discovered that potential
                  customers were more likely to use a tool that could connect to
                  their existing digital calendars, so we broadened Jugl's
                  product to include a web-based app that enabled such
                  extensions.
                  <br />
                  <br />
                  To identify scheduling challenges families face, we led a
                  design workshop that confirmed where the product's opportunity
                  laid and created a prototype to prove that parents would, in
                  fact, use it. Next, we collaborated with Jugl to determine how
                  best to launch an initial pilot and how to generate a
                  sustainable business model for the product.
                  <br />
                  <br />
                  We are currently working with Jugl to apply machine learning
                  to the logistics of working parents, so that future iterations
                  of the product will be even more helpful to its busy users.
                </SectionContent>
                <PrimaryButton
                  blank
                  darkMode
                  topSpacing
                  backgroundImage={linkBackgroundImage}
                  link="https://www.getjugl.com"
                >
                  Learn about Jugl
                </PrimaryButton>
              </MainLeft>
              <HorizontalRule />
              <MainRight>
                <SectionHeader>Services</SectionHeader>
                <PhaseDisciplineContainer>
                  <PhaseDisciplines className="discipline-bullet juglBrand">
                    Design and technology strategy
                  </PhaseDisciplines>
                  <PhaseDisciplines className="discipline-bullet juglBrand">
                    Research, including stakeholder
                    <br />
                    <div style={{ marginTop: "10px" }}>
                      and expert interviews
                    </div>
                  </PhaseDisciplines>
                  <PhaseDisciplines className="discipline-bullet juglBrand">
                    Problem definition and feature prioritization
                  </PhaseDisciplines>
                  <PhaseDisciplines className="discipline-bullet juglBrand">
                    Prototyping
                  </PhaseDisciplines>
                  <PhaseDisciplines className="discipline-bullet juglBrand">
                    User experience and visual design
                  </PhaseDisciplines>
                  <PhaseDisciplines className="discipline-bullet juglBrand">
                    Agile product development
                  </PhaseDisciplines>
                  <PhaseDisciplines className="discipline-bullet juglBrand">
                    Private beta testing
                  </PhaseDisciplines>
                  <PhaseDisciplines className="discipline-bullet juglBrand">
                    Sustainable business model development
                  </PhaseDisciplines>
                </PhaseDisciplineContainer>
                <SectionHeader>In collaboration with</SectionHeader>
                <SectionContent>
                  <a
                    href="https://flatironschool.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Designation (now Flatiron School)
                  </a>
                </SectionContent>
              </MainRight>
            </MiddleContainer>
          </Fade>
          <SectionHorizontalRule />
          <Fade bottom cascade duration={1250} distance="25px">
            <ClientQuote>
              “In a world where tech projects regularly take twice as long and
              cost three times as much as expected, Futurehaus is accurate,
              trustworthy, and dedicated. Work gets done right the first time.”
            </ClientQuote>
            <ClientName>
              Amy Rosenow, Founder and CEO
              <br />
              Jugl
            </ClientName>
          </Fade>
          <Contact small darkMode backgroundImage={linkBackgroundImage} />
          <MoreWork>
            <Fade left distance="20px">
              <AniLink
                to="/work/exelon"
                cover
                direction="left"
                bg="#222533"
                className="caseLink"
              >
                <PreviousWork>
                  <ButtonArrow>←</ButtonArrow>
                  <MoreClient>Exelon</MoreClient>
                </PreviousWork>
              </AniLink>
            </Fade>
            <WorkSpacer />
            <Fade right distance="20px">
              <AniLink
                to="/work/worldwe"
                cover
                direction="right"
                bg="#222533"
                className="caseLink"
              >
                <NextWork>
                  <MoreClient>WorldWE</MoreClient>
                  <ButtonArrow>→</ButtonArrow>
                </NextWork>
              </AniLink>
            </Fade>
          </MoreWork>
        </Layout>
      </IndexContainer>
    )
  }
}

export default CaseStudy
