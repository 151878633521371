import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "styled-media-query"

const StyledImage = styled(Img)`
  width: 696px;
  height: 697px;
  margin-left: -240px;

  ${media.lessThan("medium")`
    max-width: 350px;
    max-height: 350px;
    width: 85vw;
    height: 85vw;
    margin: auto;
    margin-top: 100px;
    margin-left: -20px;
  `}
`

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      workJuglX: file(relativePath: { eq: "graphic-target-jugl.png" }) {
        childImageSharp {
          fluid(maxWidth: 696, maxHeight: 697) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <StyledImage
      fluid={data.workJuglX.childImageSharp.fluid}
      style={{
        position: "absolute",
      }}
      alt="X shape in the background."
    />
  )
}

export default Image
